* {
    font-family: 'Manrope', sans-serif !important;
}

a {
    text-decoration: none !important;
}

.my_profile-bg {
    width: 340px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.my_profile_user-img {
    border-radius: 100%;
    margin-left: -64px;
}

.property_details-img {
    width: 100%;
}

.sider {
    z-index: 1000;
}

.main-content {
    z-index: 1;
}

@media screen and (max-width: 900px) {
    .my_profile-bg {
        width: 100%;
        border-radius: 15px;
    }

    .my_profile_user-img {
        margin-left: 0px;
        margin-top: -64px;
    }

    .property_details-img {
        width: 100%;
        height: auto;
    }
}
